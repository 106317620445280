<template>
  <div>
    <!-- Components -->
    <mcTrainer />
    <pfadaufgabenTrainer />
    <battleTrainer />
    <battleInvitation :battle="loadedBattle" @declineBattle="declineBattle" @initBattle="initBattle(selectedPendingBattleId)" />
    <darkBackground />
    <deletePendingBattleMutation />
    <updatePublicBattleMutation />
    <acceptBattleMutation />
    <infoOverlay text="Noch hat sich niemand für dieses Battle eingetragen. Möchtest du es zurückziehen und ein neues erstellen?" button="Battleanfrage zurückziehen" :index=4 @buttonClicked="declineBattle" />
    <infoOverlay text="Deine Battleanfrage wurde noch nicht angenommen, möchtest du die Anfrage zurückziehen?" button="Battleanfrage zurückziehen" :index=5 @buttonClicked="declineBattle" />
    <infoOverlay text="Warte, bis dein Mitspieler die aktuelle Runde beendet hat." :index=6 />
    <infoOverlay text="Möchtest Du das Battle weiterspielen?" button="Battle öffnen" :index=7 @buttonClicked="openBattle(loadedBattle)" />
    <infoOverlay text="Das Battle Ergebnis liegt vor, möchtest du sehen, wer gewonnen hat?" button="Ergebnis anzeigen" :index=8 @buttonClicked="openBattleResults(loadedBattle)" />

    <!-- Components -->

    <!-- Background -->
    <hafn v-if="appName === 'HAFN'" class="hidden md:block fixed bottom-5 right-5 h-95 w-auto opacity-5 z-000"/>

    <!-- Content -->
    <div class="md:pl-24 pt-14 text-new-black font-visby">
      <div class="py-5 md:px-5 md:pr-10" :class="{'pt-safe' : parseInt(sat) !== 0}">

        <!-- Heading -->
        <div class="px-5 mt-6">
          <div class="font-visby font-bold text-3xl">Üben</div>
          <span>Erstelle ein neues Training oder wiederhole bereits abgeschlossene</span>
        </div>

        <!-- Slider -->
        <div ref="slider" class="mt-5 px-5 md:px-0 flex flex-row w-auto h-auto md:w-full overflow-auto scrollbar" :class="{'snap-mandatory' : mobile}"
          @mousedown.passive="slideDragStart"
          @mousemove.prevent="slideDrag"
          @mouseup.passive="slideDragEnd">
          <slideItemTraining heading="Multiple Choice" text="Erstelle Dein individuelles Multiple Choice Training." buttonText="Training starten" :showButton="true" class="flex-shrink-0 ml-5 mr-3 snap-start" @button-clicked="openMCTrainer" />
          <slideItemTraining heading="Pfadaufgaben" text="Trainiere die verschiedenen Pfadaufgaben aus den Lernpfaden" buttonText="Training starten" :showButton="true" class="flex-shrink-0 mr-3 snap-start" @button-clicked="openPfadaufgabenTrainer" />
          <slideItemTraining heading="Battle" text="Erstelle ein Multiple Choice Battle und trete gegen Deine Mitlernenden an" buttonText="Battle starten" :showButton="true" class="flex-shrink-0 snap-end" @button-clicked="openBattleTrainer"/>
          <div class="flex-shrink-0  h-5 w-5"></div>
        </div>

        <div class="md:flex flex-row">
          <div class="md:order-last md:mt-2">

            <!-- Active Battles -->
            <div v-if="myActiveBattles?.length > 0" class="px-5 mt-5 md:w-1/3 md:min-w-100">
              <div class="font-visby font-bold text-lg">Aktuelle Battles</div>
              <div v-for="battle in myActiveBattles" :key="battle" class="mt-3 md:mt-2">
                <recentItem
                  :heading="`Battle gegen ${battle?.attributes[getPlayerTwo(battle)]?.data?.attributes?.nickname}`"
                  :image="battle?.attributes[getPlayerTwo(battle)]?.data?.attributes?.avatar?.data?.attributes?.url"
                  imageType="user"
                  :subheading="`Runde ${getCurrentRound(battle)} von ${battle?.attributes?.battleRound?.length}`"
                  :status="checkStatus(battle).message"
                  @click="continueBattle(battle)" />
              </div>
            </div>

            <!-- My Battle Invitations -->
            <div v-if="myFilteredBattleInvitations?.length > 0" class="px-5 mt-5 md:w-1/3 md:min-w-100">
              <div class="font-visby font-bold text-lg">Fremde Battle Anfragen</div>
              <div v-for="battle in myFilteredBattleInvitations" :key="battle" class="mt-3 md:mt-2">
                <recentItem
                  :heading="`Battleanfrage von ${battle?.attributes?.creator?.data?.attributes?.nickname}`"
                  :image="battle?.attributes?.creator?.data?.attributes?.avatar?.data?.attributes?.url"
                  imageType="user"
                  subheading=""
                  :status="battle.attributes.public ? 'Öffentliches Battle' : 'Privates Battle'"
                  @click="openBattleInvite(battle)" />
              </div>
            </div>

            <!-- My Battle Request -->
            <div v-if="myFilteredPendingBattles?.length > 0" class="px-5 mt-5 md:w-1/3 md:min-w-100">
              <div class="font-visby font-bold text-lg">Meine Battle Einladungen</div>
              <div v-for="battle in myFilteredPendingBattles" :key="battle" class="mt-3 md:mt-2">
                <recentItem
                  :heading="battle.attributes.public ? 'Öffentliche Battleanfrage' : `Battleanfrage an ${battle?.attributes?.opponent?.data?.attributes?.nickname}`"
                  :subheading="battle.attributes.public ? 'Warten auf Teilnehmer' : 'Antwort ausstehend'"
                  :image="battle?.attributes?.opponent?.data?.attributes?.avatar?.data?.attributes?.url"
                  :imageType="battle.attributes.public ? '' : 'user'"
                  :status="battle.attributes.public ? 'Öffentliches Battle' : 'Privates Battle'"
                  @click="openBattleRequest({ id:battle?.id, publicBattle: battle.attributes.public })" />
              </div>
            </div>
          </div>

          <!-- Recent trainings -->
          <recentTrainings />
        </div>

        <!-- Scrollspace Mobile -->
        <div class="mb-24 md:hidden"></div>

      </div>
    </div>
    <!-- Content -->
  </div>
</template>

<script>
// Strapi
import deletePendingBattleMutation from './strapi/mutations/deletePendingBattleMutation.vue';
import updatePublicBattleMutation from './strapi/mutations/updatePublicBattleMutation.vue';
import acceptBattleMutation from './strapi/mutations/acceptBattleMutation.vue';

// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';
import battleFunctions from '../../mixins/battleFunctions.vue';
import slideDrag from '../../mixins/slideDrag.vue';
import mobile from '../../mixins/mobile.vue';

// Overlays
import mcTrainer from './overlays/mcTrainer.vue';
import pfadaufgabenTrainer from './overlays/pfadaufgabenTrainer.vue';
import battleTrainer from './overlays/battleTrainer.vue';
import battleInvitation from './overlays/battleInvitation.vue';

// Components
import slideItemTraining from './components/slideItemTraining.vue';
import darkBackground from '../../symbols/darkBackground.vue';
import infoOverlay from '../../symbols/infoOverlay.vue';
import recentItem from '../dashboard/components/recentItem.vue';
import recentTrainings from './components/recentTrainigs.vue';

// SVG
import hafn from '../../assets/svg/hafn.vue';

export default {
  name: 'training',
  mixins: [iosVibrations, detectNotch, battleFunctions, slideDrag, mobile],
  components: {
    mcTrainer,
    darkBackground,
    infoOverlay,
    hafn,
    slideItemTraining,
    recentItem,
    pfadaufgabenTrainer,
    battleTrainer,
    battleInvitation,
    deletePendingBattleMutation,
    updatePublicBattleMutation,
    acceptBattleMutation,
    recentTrainings,
  },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      loadedBattle: null,
      selectedPendingBattleId: null,
    };
  },

  computed: {
    currentCourseID() {
      return this.$store.state?.settings.currentCourseID;
    },
    myBattleInvitations() {
      return this.$store.state.profile?.myBattles?.battleInvites?.data;
    },
    myFilteredBattleInvitations() {
      const invitations = [];
      if (this.myBattleInvitations) {
        this.myBattleInvitations.forEach((battle) => {
          if (!battle.attributes.started) {
            invitations.push(battle);
          }
        });
      }
      return invitations;
    },
    myPendingBattles() {
      return this.$store.state.profile?.myBattles?.pendingBattles?.data;
    },
    myFilteredPendingBattles() {
      const requests = [];
      if (this.myPendingBattles) {
        this.myPendingBattles.forEach((battle) => {
          if (!battle.attributes.started) {
            requests.push(battle);
          }
        });
      }
      return requests;
    },
    myActiveBattles() {
      const startedBattles = [];
      let creatorOrOpponent = 'creatorResultsSeen';
      if (this.myBattleInvitations) {
        this.myBattleInvitations.forEach((battle) => {
          if (battle.attributes.opponent?.data?.id === this.$store.state.profile.me?.userData?.data?.id) creatorOrOpponent = 'opponentResultsSeen';
          if (battle.attributes.started && !battle.attributes[creatorOrOpponent]) {
            startedBattles.push(battle);
          }
        });
      }
      if (this.myPendingBattles) {
        this.myPendingBattles.forEach((battle) => {
          if (battle.attributes.opponent?.data?.id === this.$store.state.profile.me?.userData?.data?.id) creatorOrOpponent = 'opponentResultsSeen';
          if (battle.attributes.started && !battle.attributes[creatorOrOpponent]) {
            startedBattles.push(battle);
          }
        });
      }
      return startedBattles;
    },
    deletePendingBattle() {
      return this.$store.state.deletePendingBattle;
    },
    queryMyBattlesEx() {
      return this.$store.state.queryMyBattlesEx;
    },
  },

  mounted() {
    this.queryMyBattlesEx();
  },

  methods: {
    getPlayerTwo(battle) {
      if (battle?.attributes?.creator?.data?.id === this.$store.state.profile.me?.userData?.data?.id) {
        return 'opponent';
      }
      return 'creator';
    },

    getCurrentRound(battle) {
      let creatorRounds = 1;
      let opponentRounds = 1;
      const maxRounds = battle.attributes.battleRound.length;
      battle.attributes.creatorProgress.rounds.forEach((round) => {
        if (round.finished) creatorRounds += 1;
      });
      battle.attributes.opponentProgress.rounds.forEach((round) => {
        if (round.finished) opponentRounds += 1;
      });
      const currentRound = Math.min(creatorRounds, opponentRounds);
      if (currentRound > maxRounds) {
        return maxRounds;
      }
      return currentRound;
    },

    checkStatus(battle) {
      let creatorOrOpponent = 'creatorResultsSeen';
      if (battle.attributes.creator?.data?.id === this.$store.state.profile.me?.userData?.data?.id) creatorOrOpponent = 'opponentResultsSeen';
      const playerTwo = this.getPlayerTwo(battle);
      let creatorRounds = 1;
      let opponentRounds = 1;
      battle.attributes.creatorProgress.rounds.forEach((round) => {
        if (round.finished) creatorRounds += 1;
      });
      battle.attributes.opponentProgress.rounds.forEach((round) => {
        if (round.finished) opponentRounds += 1;
      });
      if (battle.attributes[creatorOrOpponent]) {
        return { status: 3, message: 'Ergebnis liegt vor' };
      }
      if ((playerTwo === 'creator' && creatorRounds >= opponentRounds) || (playerTwo === 'opponent' && opponentRounds >= creatorRounds)) {
        return { status: 1, message: 'Du bist am Zug' };
      }
      return { status: 2, message: 'Warte auf Mitspieler' };
    },

    openMCTrainer() {
      this.iosVibrate(0);
      this.$store.dispatch('resetTraining', { type: 'mcTraining' });
      this.$store.commit('showOverlay', 'mcTrainer');
    },

    openPfadaufgabenTrainer() {
      this.iosVibrate(0);
      this.$store.dispatch('resetTraining', { type: 'Pfadaufgabe' });
      this.$store.commit('showOverlay', 'pfadaufgabenTrainer');
    },

    openBattleTrainer() {
      this.iosVibrate(0);
      this.$store.dispatch('resetTraining', { type: 'Battle' });
      this.$store.commit('showOverlay', 'battleTrainer');
    },

    openBattleInvite(battle) {
      const settings = battle?.attributes?.settings;
      this.loadedBattle = battle;
      this.iosVibrate(0);
      this.selectedPendingBattleId = battle.id;
      this.$store.dispatch('resetTraining', { settings, type: 'mcTraining' });
      this.$store.commit('showOverlay', 'battleInvitation');
    },

    openBattleRequest({ id, publicBattle }) {
      this.iosVibrate(0);
      this.selectedPendingBattleId = id;
      if (publicBattle) {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 4 });
      } else {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 5 });
      }
    },

    declineBattle() {
      this.iosVibrate(0);
      this.deletePendingBattle({ id: this.selectedPendingBattleId }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          this.queryMyBattlesEx();
        }
      });
    },

    continueBattle(battle) {
      if (this.checkStatus(battle).status === 1) {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 7 });
        this.loadedBattle = battle;
      } else if (this.checkStatus(battle).status === 2) {
        console.log('already Done');
        this.$store.commit('toggleInfoOverlay', { value: true, index: 6 });
      } else {
        console.log('show results');
        this.$store.commit('toggleInfoOverlay', { value: true, index: 8 });
        this.loadedBattle = battle;
      }
    },

    countAnsweredQuestions(progress) {
      let count = 0;
      if (progress) {
        progress.forEach((question) => {
          if (question.submitted) {
            count += 1;
          }
        });
      }
      return count;
    },
  },
};

</script>
