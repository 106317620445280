<template>
<infoOverlay text="Wähle eine andere Filterkombination, um mehr Fragen zu erhalten." :index=21 :level=2 />
 <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'mcTrainer'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-visby text-new-black pointer-events-none">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Components -->
        <darkBackground2 />
        <subjectPicker :all="true" />
        <tagPicker />
        <examPicker />
        <statPicker />
        <querySubjects />
        <listSettingPicker listType="mcFragen"/>
        <preQueryQuestions />
        <queryQuestions />
        <queryMcExams />
        <!-- Components -->

        <!-- Content -->
        <div class="w-full h-1/6 flex-shrink-0" @click="closeMcTrainer"></div>
        <div class="w-full flex-grow bg-new-yellow rounded-t-3xl p-5 overflow-y-scroll scrollbar">

          <!-- Heading -->
          <div class="md:hidden text-xl cursor-pointer" @click="closeMcTrainer">schließen</div>
          <div class="mt-3 md:mt-0 font-visby font-bold text-3xl">Neues Training</div>
          <div class="text-xl">Multiple Choice</div>

          <!-- Filter Block -->
          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-new-yellow2">
            <setting label='Filter nach Fächern' showicon=true icon='faecher' :border=true action='chevron' truncate=true :additionalText="formatText(settings?.subjects) || 'Optional'" @click="openPicker('subjectPicker')" />
            <setting v-if="exams" label='Filter nach Prüfungen' showicon=true icon='exam' action='chevron' :border=true :additionalText="formatText(settings?.exams) || 'Optional'" @click="openPicker('examPicker')" />
            <setting v-if="settings?.subjects.length > 0 && preTags?.length > 0" label='Filter nach Unterthemen' showicon=true icon='tag' :border=true action='chevron' :additionalText="formatText(settings?.tags) || 'Optional'" @click="openPicker('tagPicker')" />
            <setting v-if="wrongPlayed || recentPlayed" label='Filter nach Statistik' showicon=true icon='adjustment' :border=true action='chevron' :additionalText="formatText(stats) || 'Optional'" @click="openPicker('statPicker')" />
            <setting label='Filter nach eigenen Listen' showicon=true icon='list' action='chevron' :additionalText="formatText(settings?.lists) || 'Optional'" @click="openPicker('listSettingPicker')" />
          </div>

          <!-- Options Block -->
          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-new-yellow2">
            <setting label='Training auf Zeit' additionalText='Zeit pro Frage' showicon=true icon='time' border=true action='toggle' additionalAcion='choose' addActionLabel='Minuten:' addActionDefault=1 addActionStoreID='minutes' storeID='time' storeContainer='training' storeWrapper='settings'/>
            <setting label='Begrenzte Anzahl Fragen' showicon=true icon='chat' border=true action='toggle' additionalAcion='numInput' addActionLabel='Fragen:' addActionDefault=20 addActionStoreID='questions' storeID='limitedQuestions' storeContainer='training' storeWrapper='settings' />
          </div>

          <!-- Options Block -->
          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-new-yellow2">
            <setting label='Prüfungsmodus' additionalText='Erst am Ende siehst du, wie viele Fragen du richtig beantwortet hast.' showicon=true icon='hat' action='toggle' storeID='exam' storeContainer='training' storeWrapper='settings' />
          </div>



        </div>
        <!-- Content -->

        <!-- Button -->
        <div class="flex-shrink-0 pb-5 min-h-28 w-full p-5 bg-white md:bg-new-yellow shadow-top md:shadow-none md:border-t-2 md:border-new-yellow2">
          <customButton v-if="settings.time" label='Training starten' :info="`(${questions.length} Fragen, ${settings.minutes * questions.length} Minuten)`" :loading="isFetching" @click="startTraining"/>
          <customButton v-else label='Training starten' :info="`(${questions.length} Fragen)`" :loading="isFetching" @click="startTraining"/>
        </div>
        <!-- Button -->

      </div>
    </div>
  </transition>
</template>

<script>
// Strapi
import preQueryQuestions from '../strapi/queries/preQueryQuestions.vue';
import queryQuestions from '../strapi/queries/queryQuestions.vue';
import queryMcExams from '../strapi/queries/queryMcExams.vue';
import querySubjects from '../strapi/queries/querySubjects.vue';

// Components
import darkBackground2 from '../../../symbols/darkBackground2.vue';
import setting from '../../../symbols/setting.vue';
import customButton from '../../../symbols/customButton.vue';
import subjectPicker from '../components/subjectPicker.vue';
import tagPicker from '../components/tagPicker.vue';
import listSettingPicker from '../components/listSettingPicker.vue';
import examPicker from '../components/examPicker.vue';
import statPicker from '../components/statPicker.vue';
import infoOverlay from '../../../symbols/infoOverlay.vue';

// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import detectNotch from '../../../mixins/detectNotch.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

export default {
  name: 'mcTrainer',
  mixins: [slideInOutBottom, detectNotch, iosVibrations],
  components: {
    setting,
    customButton,
    subjectPicker,
    infoOverlay,
    tagPicker,
    examPicker,
    statPicker,
    preQueryQuestions,
    darkBackground2,
    queryQuestions,
    listSettingPicker,
    queryMcExams,
    querySubjects,
  },

  computed: {
    me() {
      return this.$store.state.profile.me;
    },
    questions() {
      return this.$store.state.training.currentTraining.questionIds;
    },
    preQuestions() {
      return this.$store.state.training.preQuestions;
    },
    preLimitedQuestions() {
      return this.$store.state.training.preLimitedQuestions;
    },
    isFetching() {
      if (this.$store.state.training.isFetchingPreQuestions || this.$store.state.training.isFetchingQuestions) {
        return true;
      }
      return false;
    },
    preTags() {
      return this.$store.state.training.preTags;
    },
    currentCourseIndex() {
      return this.$store.state.settings.currentCourseIndex;
    },
    exams() {
      return this.$store.state.training.mcExams;
    },
    scrollDown() {
      return this.$store.state.layout.scrollDown;
    },
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    settings() {
      return this.$store.state.training?.settings;
    },
    questionLimit() {
      return this.$store.state.training?.settings?.questions;
    },
    wrongPlayed() {
      return this.$store.state.profile.me.userData.data.attributes.wrongPlayedQuestions.ids;
    },
    recentPlayed() {
      return this.$store.state.profile.me.userData.data.attributes.recentPlayedQuestions.ids;
    },
    stats() {
      return this.$store.state.training?.settings?.stats;
    },
  },

  watch: {
    preQuestions() {
      this.pickRandomQuestions();
    },
    questionLimit() {
      this.pickRandomQuestions();
    },
  },

  mounted() {
    // Imitate inital fetch
    this.$store.commit('setFetchingIndicator', { key: 'PreQuestions', value: true });
  },

  methods: {
    pickRandomQuestions() {
      if (this.preQuestions) {
        const array = [...this.preQuestions];
        const randomArray = [];
        for (let i = 0; i < this.questionLimit && i < this.preQuestions.length; i += 1) {
          const max = array.length;
          const index = Math.floor(Math.random() * max);
          randomArray.push(array[index]);
          array.splice(index, 1);
        }
        this.$store.commit('setPreLimitedQuestions', randomArray);
      }
    },

    closeMcTrainer() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },

    openPicker(value) {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', value);
    },

    formatText(array) {
      return array.join(', ');
    },

    startTraining() {
      if (!this.isFetching) {
        if (this.questions.length <= 1) {
          this.iosVibrate(1);
          this.$store.commit('toggleInfoOverlay', { value: true, index: 21, level: 2 });
        } else {
          this.iosVibrate(1);
          this.$store.commit('addToggle', {
            storeID: 'review', storeContainer: 'training', storeWrapper: 'settings', value: false,
          });
          this.$store.commit('startTraining', { type: 'mcTraining' });
          this.$router.push({ name: 'questions' });
        }
      }
    },
  },
};
</script>
