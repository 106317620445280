<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryMcExams',
  watch: {
    mcExams() {
      this.$store.commit('setMcExams', this.mcExams?.pruefungens?.data);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const queryMcExams = gql`
    query($courseId: ID!) {
      pruefungens(
        pagination: {limit: 100}
        sort: "Index:desc"
        filters: { availableForTraining: { eq: true }, kurs: {id: {eq: $courseId}} }
        ) {
        data {
          id
          attributes {
            Index
          }
        }
      }
    }
    `;

    const variables = reactive({
      courseId: computed(() => store.state.settings.currentCourseID),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: mcExams } = useQuery({
      query: queryMcExams,
      variables,
      context,
    });
    return { mcExams };
  },
};
</script>
