<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryQuestions',
  watch: {
    questions() {
      this.$store.commit('setQuestions', this.questions);
    },
    isFetching() {
      this.$store.commit('setFetchingIndicator', { key: 'Questions', value: this.isFetching });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const queryQuestions = gql`
    query($id: [ID] ) {
        mcFragens(
          filters: {id: {in: $id }}
        ) {
          data {
            id
            attributes {
              Index
              Frage
              Kommentar
              sumCorrect
              Antwort {
                id
                Antwort
              }
              pruefungen {
                data {
                  attributes {
                    Index
                  }
                }
              }
              lernpfade {
                data {
                  attributes {
                    Bezeichnung
                  }
                }
              }
              tags {
                data {
                  id
                  attributes {
                    Bezeichnung
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      id: computed(() => store.state.training.preLimitedQuestions),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: questions, isFetching } = useQuery({
      query: queryQuestions,
      variables,
      context,
      fetchOnMount: false,
    });
    return { questions, isFetching };
  },
};
</script>
