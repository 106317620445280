<template>
<infoOverlay text="Möchtest Du das Training fortsetzen?" button="Training fortsetzen" :index=2 @buttonClicked="loadExistingTrainig" />
<infoOverlay text="Möchtest Du das Training wiederholen?" button="Training wiederholen" :index=3 @buttonClicked="replayExistingTraining" />
  <div class="px-5 mt-5 md:w-1/3 md:min-w-100 text-new-black font-visby">
    <div class="font-visby font-bold text-lg">Zuletzt trainiert</div>
    <div class="flex-shrink-0 mt-0.5 flex flex-row text-new-midGrey overflow-y-scroll whitespace-nowrap scrollbar">
      <div v-for="filter in filter" :key=filter class="cursor-pointer mr-3 select-none" @click="currentFilter = filter">
        <div :class="{'text-new-black' : filter === currentFilter}">{{filter}}</div>
      </div>
    </div>
    <div v-for="(training, index) in myFilteredTrainings" :key="training" class="mt-3 md:mt-2">
      <recentItem v-if="training?.courseId == currentCourseID"
        :heading="training?.type"
        :subheading="getTags(training?.currentTraining)"
        :status="training?.currentTraining?.finished ? 'abgeschlossen' : 'offen'"
        :showCircle="training?.currentTraining?.finished"
        :done="getDone(training?.currentTraining?.progress)"
        :total="training?.currentTraining?.progress?.length"
        @click="loadTraining(index, training?.currentTraining?.finished, training?.type)" />
    </div>
    <div v-if="!myFilteredTrainings.some((training) => training?.courseId == currentCourseID)" class="w-full flex flex-col justify-center items-center mt-10 p-5 text-center">
      <div>Keine Trainings verfügbar</div>
      <div v-if="!location" class="text-new-midGrey">Sieht aus, als hättest du noch kein Training der Kategorie "{{ currentFilter }}"" absolviert. Starte oben ein neues Training.</div>
      <div v-else class="text-new-midGrey">Sieht aus, als hättest du noch kein Training der Kategorie "{{ currentFilter }}"" absolviert. Gehe in den Trainingsbereich und start ein neues Training.</div>
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import recentItem from '../../dashboard/components/recentItem.vue';
import infoOverlay from '../../../symbols/infoOverlay.vue';

export default {
  name: 'recentTrainings',
  props: ['location'],
  components: { recentItem, infoOverlay },
  mixins: [iosVibrations],

  data() {
    return {
      index: null,
      selectedType: null,
      currentFilter: 'Alle',
      filter: ['Alle', 'Multiple Choice', 'Pfadaufgabe'],
    };
  },

  computed: {
    currentCourseID() {
      return this.$store.state?.settings.currentCourseID;
    },
    myRecentTrainings() {
      return this.$store.state?.profile?.me?.userData?.data?.attributes?.recentTrainings;
    },
    myFilteredTrainings() {
      let filteredTraining = [];
      if (this.currentFilter === 'Alle') {
        filteredTraining = this.myRecentTrainings;
      } else {
        this.myRecentTrainings.forEach((training) => {
          if (training.type === this.currentFilter) filteredTraining.push(training);
        });
      }
      filteredTraining.sort((a, b) => b.id - a.id);
      return filteredTraining;
    },
  },

  methods: {

    getDone(progress) {
      let count = 0;
      if (progress) {
        progress.forEach((item) => {
          if (item.result === 'correct' || item.result === 'success') count += 1;
        });
      }
      return count;
    },

    getTags(currentTraining) {
      const tags = [];
      currentTraining.questionData.forEach((question) => {
        question.attributes.lernpfade.data.forEach((lernpfad) => {
          if (!tags.includes(lernpfad.attributes.Bezeichnung)) {
            tags.push(lernpfad.attributes.Bezeichnung);
          }
        });
        const pruefungIndex = question?.attributes?.pruefungen?.data?.attributes?.Index;
        if (pruefungIndex && !tags.includes(pruefungIndex)) tags.push(pruefungIndex);
        question.attributes.tags.data.forEach((tag) => {
          if (!tags.includes(tag.attributes.Bezeichnung)) {
            tags.push(tag.attributes.Bezeichnung);
          }
        });
      });
      return tags.join(', ');
    },

    loadTraining(index, finished, type) {
      this.iosVibrate(0);
      this.index = index;
      this.selectedType = type;
      this.$store.dispatch('resetTraining', { type });
      if (finished) {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 3 });
      } else {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 2 });
      }
    },

    loadExistingTrainig() {
      this.$store.commit('loadTraining', this.myRecentTrainings[this.index]);
      this.$router.push({ name: this.selectedType === 'Multiple Choice' ? 'questions' : 'exercises' });
    },

    replayExistingTraining() {
      this.$store.dispatch('replayTraining', this.myRecentTrainings[this.index]);
      this.$router.push({ name: this.selectedType === 'Multiple Choice' ? 'questions' : 'exercises' });
    },
  },
};
</script>
