<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'statPicker'" class="fixed md:absolute top-0 left-0 w-full h-screen md:h-full z-50 flex flex-row justify-center items-center pointer-events-none text-new-black font-visby">
      <div class="w-full h-full absolute bottom-0 pointer-events-auto">

        <!-- Content -->
        <div class="w-full h-1/6" @click="hidePicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">
          <!-- Fixed -->
          <div class="h-auto">
            <div class="text-xl" @click="hidePicker">schließen</div>
            <div class="mt-2 font-visby font-bold text-3xl">Filter nach Statistiken</div>
          </div>
          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">

          <div class="w-full md:auto bg-white rounded-xl shadow-new mt-5 border border-new-yellow2 overflow-hidden">
            <selector v-if="wrongPlayed"
              label="Falsch gespielte"
              storeContainer='training'
              storeWrapper='settings'
              storeID='stats'
              storeID2='wrongPlayedID'
              :value2=wrongPlayed
              :isArray=true
              :border=true />
            <selector v-if="recentPlayed"
              label="Lange nicht gespielte"
              storeContainer='training'
              storeWrapper='settings'
              storeID='stats'
              storeID2='recentPlayedID'
              :value2=recentPlayed
              :isArray=true
              :border=true />
            <selector v-if="totalPlayed"
              label="Noch nicht gespielte"
              storeContainer='training'
              storeWrapper='settings'
              storeID='stats'
              storeID2='totalPlayedID'
              :value2=totalPlayed
              :isArray=true
              :border=true />
          </div>

          </div>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import selector from '../../../symbols/selector.vue';

export default {
  name: 'statPicker',
  props: ['current', 'type'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { selector },

  computed: {
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    wrongPlayed() {
      if (this.type === 'pfadaufgaben') return this.$store.state.profile.me.userData.data.attributes.wrongPlayedExercise.ids;
      return this.$store.state.profile.me.userData.data.attributes.wrongPlayedQuestions.ids;
    },
    recentPlayed() {
      if (this.type === 'pfadaufgaben') return this.$store.state.profile.me.userData.data.attributes.recentPlayedExercise.ids;
      return this.$store.state.profile.me.userData.data.attributes.recentPlayedQuestions.ids;
    },
    totalPlayed() {
      if (this.type === 'pfadaufgaben') return this.$store.state.profile.me.userData.data.attributes.totalPlayedExercises.ids;
      return this.$store.state.profile.me.userData.data.attributes.totalPlayedQuestions.ids;
    },
  },

  methods: {
    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', null);
    },
  },
};
</script>
