<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'createNewPublicBattleMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const createNewBattleMutation = gql`
    mutation($creator: ID!, $course: ID!, $settings: JSON!, $opponent: ID, $public: Boolean!) {
      createPublicBattle(data: {
        settings: $settings,
        creator: $creator,
        opponent: $opponent,
        public: $public,
        course: $course
      }) {
        data {
          id
        }
      }
    }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: createNewBattle } = useMutation(createNewBattleMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'createNewBattle', result: createNewBattle });
  },
};
</script>
