<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'deletePendingBattleMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const deletePendingBattleMutation = gql`
      mutation($id: ID!) {
        deletePublicBattle(id: $id) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: deletePendingBattle } = useMutation(deletePendingBattleMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'deletePendingBattle', result: deletePendingBattle });
  },
};
</script>
