<script>
export default {
  name: 'slideDrag',

  data() {
    return {
      dragItem: null,
      initialScrollLeft: 0,
      initialX: null,
      currentX: null,
    };
  },

  methods: {
    slideDragStart(e) {
      this.dragItem = this.$refs.slider;
      if (e.type === 'touchstart') {
        this.initialX = e.touches[0].clientX;
      } else {
        this.initialX = e.clientX;
      }
    },

    slideDrag(e) {
      if (this.dragItem) {
        if (e.type === 'touchmove') {
          this.currentX = e.touches[0].clientX - this.initialX;
        } else {
          this.currentX = e.clientX - this.initialX;
        }
        this.dragItem.scrollLeft = this.initialScrollLeft + (this.currentX * -1);
      }
    },

    slideDragEnd() {
      this.initialScrollLeft = this.dragItem.scrollLeft;
      this.dragItem = null;
    },
  },

};
</script>
