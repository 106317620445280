<template>
  <div class="bg-white rounded-xl h-auto w-4/5 md:min-w-80 md:w-1/4 shadow-soft text-new-black font-visby flex flex-col justify-between items-center p-5 px-5 break-words" :class="{'opacity-50' : deactivated, 'w-full' : single}">
    <div class=" w-full break-words">
      <div class="font-visby font-bold text-xl text-center break-words hyphen">{{ heading }}</div>
      <div class="text-center mt-3">{{ text }}</div>
      <div v-if="timer && startTime && getDif() > 0" @click="refresh" class=" px-3 text-center mt-3 text-sm rounded-lg py-1 flex flex-row justify-center items-center" :style="{'background-color': currentCourseColor3, 'color': currentCourseColor}">
        <div>{{ getRemainingTime() }}</div>
        <div class="cursor-pointer p-0.5 ml-2">
          <reload />
        </div>
      </div>
    </div>
    <div v-if="showButton || getDif() < 0" class="flex flex-row justify-center items-center mt-5 rounded-xl shadow-soft px-5 py-2 cursor-pointer" :style="{'background-color': currentCourseColor2}" @click="$emit('button-clicked')">
      <div class="text-center">{{ buttonText }}</div>
      <chevron class="ml-2 flex-shrink-0" />
    </div>
  </div>
</template>

<script>
// Mixins
import colors from '../../../mixins/colors.vue';

// SVG
import chevron from '../../../assets/svg/chevron.vue';
import reload from '../../../assets/svg/reload.vue';

export default {
  name: 'slideItemTraining',
  props: ['heading', 'text', 'buttonText', 'deactivated', 'single', 'showButton', 'timer', 'startTime'],
  emits: ['button-clicked'],
  mixins: [colors],
  components: { chevron, reload },

  methods: {
    refresh() {
      this.$forceUpdate();
    },
    getRemainingTime() {
      const now = new Date();
      const dif = Date.parse(this.startTime) - Date.parse(now);
      const days = dif / 1000 / 60 / 60;
      const totalDays = Math.floor(days / 24);
      const remainingHours = days % 24;
      const totalHours = Math.floor(remainingHours);
      const remainingMinutes = Math.floor((remainingHours * 60) % 60);
      return `(Noch ${totalDays} ${totalDays === 1 ? 'Tag' : 'Tage'}, ${totalHours} ${totalHours === 1 ? 'Stunde' : 'Stunden'}, ${remainingMinutes} ${remainingMinutes === 1 ? 'Minute)' : 'Minuten)'}`;
    },
    getDif() {
      const now = new Date();
      const dif = Date.parse(this.startTime) - Date.parse(now);
      return dif;
    },
  },
};
</script>

<style>
.hyphen {
    -webkit-hyphens: auto; /* for Safari */
    hyphens: auto;
}
</style>
