<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'battlePicker'" class="fixed md:absolute top-0 left-0 w-full h-screen md:h-full z-50 flex flex-row justify-center items-center pointer-events-none text-new-black font-visby">
      <div class="w-full h-full absolute bottom-0 pointer-events-auto">

        <!-- Content -->
        <div class="w-full h-1/6" @click="hidePicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">

          <!-- Fixed -->
          <div class="h-auto">
            <div class="text-xl" @click="hidePicker">schließen</div>
            <div class="mt-2 font-visby font-bold text-3xl">Wähle ein öffentliches Battle</div>
          </div>

          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">

            <div v-if="filteredPublicBattles" class="w-full md:auto bg-white rounded-xl shadow-new mt-5 border border-new-yellow2 overflow-hidden">
             <div v-for="(battle, index) in filteredPublicBattles" :key="battle">
              <div class="w-full p-3 flex flex-row justify-between items-center font-visby cursor-pointer" :class="{ 'border-b border-new-yellow' : index < filteredPublicBattles.length -1, 'bg-new-black' : selectedBattle === index}"
              @click="toggleBattle(index, battle?.attributes?.settings, battle?.attributes?.creator, battle.id)">
                <div  class="relative w-10 h-10 rounded-full bg-white mr-5 flex flex-row justify-center items-center border-2 border-new-yellow2 overflow-hidden">
                  <div v-if="battle?.attributes?.creator?.data?.attributes?.avatar?.data?.attributes?.url" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + battle?.attributes?.creator?.data?.attributes?.avatar?.data?.attributes?.url + ')' }"></div>
                  <profileSVG v-else />
                </div>
                <div class="flex-grow pr-2 flex flex-row justify-between items-center">
                  <div class="text-lg" :class="{'text-white' : selectedBattle === index}">{{ battle?.attributes?.creator?.data?.attributes?.nickname }} - {{ battle?.attributes?.settings?.questions }} Fragen</div>
                  <checkSVG v-if="selectedBattle === index" />
                </div>
              </div>
            </div>
          </div>

          </div>

        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// TODO: Empty states, tranisitions on click
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// SVG
import profileSVG from '../../../assets/svg/profile.vue';
import checkSVG from '../../../assets/svg/check.vue';

export default {
  name: 'battlePicker',
  mixins: [slideInOutBottom, iosVibrations],
  components: { profileSVG, checkSVG },


  data() {
    return {
      selectedBattle: null,
    };
  },

  computed: {
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    publicBattles() {
      return this.$store.state.training.publicBattles;
    },
    myUserDataId() {
      return this.$store.state.profile.me?.userData?.data?.id;
    },
    filteredPublicBattles() {
      const filteredBattles = [];
      this.publicBattles.forEach((battle) => {
        if (battle.attributes.creator.data.id !== this.myUserDataId) filteredBattles.push(battle);
      });
      return filteredBattles;
    },
  },

  methods: {

    toggleBattle(index, settings, creator, id) {
      if (this.selectedBattle === index) {
        this.selectedBattle = null;
        this.$store.dispatch('resetTraining', { settings: null, type: 'battle' });
      } else {
        this.selectedBattle = index;
        const newSettings = settings;
        newSettings.publicBattleId = id;
        newSettings.battlePlayerId = creator.data.id;
        newSettings.battlePlayerName = creator.data.attributes.nickname;
        this.$store.dispatch('resetTraining', { settings: newSettings, type: 'battle' });
      }
    },

    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', null);
    },
  },
};
</script>
