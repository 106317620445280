<template>
<infoOverlay text="Wähle eine andere Filterkombination, um mehr Fragen zu erhalten." :index=31 :level=2 />
 <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'pfadaufgabenTrainer'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-visby text-new-black pointer-events-none">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Components -->
        <darkBackground2 />
        <subjectPicker />
        <tagPicker />
        <statPicker type="pfadaufgaben" />
        <listSettingPicker listType="pfadaufgaben" />
        <preQueryPfadaufgaben />
        <queryPfadaufgaben />
        <!-- Components -->

        <!-- Content -->
        <div class="w-full h-1/6 flex-shrink-0" @click="closeTrainer"></div>
        <div class="w-full flex-grow bg-new-yellow rounded-t-3xl p-5 overflow-y-scroll scrollbar">

          <!-- Heading -->
          <div class="md:hidden text-xl cursor-pointer" @click="closeTrainer">schließen</div>
          <div class="mt-3 md:mt-0 font-visby font-bold text-3xl">Neues Training</div>
          <div class="text-xl">Pfadaufgaben</div>

          <!-- Filter Block -->
          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-new-yellow2">
            <setting label='Filter nach Fächern' showicon=true icon='faecher' :border=true action='chevron' truncate=true :additionalText="formatText(settings?.subjects) || 'Optional'" @click="openPicker('subjectPicker')" />
            <setting v-if="settings?.subjects.length > 0 && preTags?.length > 0" label='Filter nach Unterthemen' showicon=true icon='tag' :border=true action='chevron' :additionalText="formatText(settings?.tags) || 'Optional'" @click="openPicker('tagPicker')" />
            <setting v-if="wrongPlayed || recentPlayed" label='Filter nach Statistik' showicon=true icon='adjustment' :border=true action='chevron' :additionalText="formatText(stats) || 'Optional'" @click="openPicker('statPicker')" />
            <setting label='Filter nach eigenen Listen' showicon=true icon='list' action='chevron' :additionalText="formatText(settings?.lists) || 'Optional'" @click="openPicker('listSettingPicker')" />
          </div>

          <!-- Options Block -->
          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-new-yellow2">
            <setting label='Begrenzte Anzahl Fragen' showicon=true icon='chat' border=true action='toggle' additionalAcion='numInput' addActionLabel='Fragen:' addActionDefault=20 addActionStoreID='questions' storeID='limitedQuestions' storeContainer='training' storeWrapper='settings' />
          </div>

        </div>
        <!-- Content -->

        <!-- Button -->
        <div class="flex-shrink-0 pb-5 min-h-28 w-full p-5 bg-new-yellow md:bg-new-yellow shadow-top md:shadow-none md:border-t-2 md:border-new-yellow2">
          <customButton label='Training starten' :info="`(${pfadaufgaben.length} Aufgaben)`" :loading="isFetching" @click="startTraining"/>
        </div>
        <!-- Button -->

      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import detectNotch from '../../../mixins/detectNotch.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import darkBackground2 from '../../../symbols/darkBackground2.vue';
import setting from '../../../symbols/setting.vue';
import customButton from '../../../symbols/customButton.vue';
import subjectPicker from '../components/subjectPicker.vue';
import tagPicker from '../components/tagPicker.vue';
import listSettingPicker from '../components/listSettingPicker.vue';
import statPicker from '../components/statPicker.vue';
import infoOverlay from '../../../symbols/infoOverlay.vue';

// Strapi
import preQueryPfadaufgaben from '../strapi/queries/preQueryPfadaufgaben.vue';
import queryPfadaufgaben from '../strapi/queries/queryPfadaufgaben.vue';

export default {
  name: 'pfadaufgabenTrainer',
  mixins: [slideInOutBottom, detectNotch, iosVibrations],
  components: {
    darkBackground2, setting, customButton, subjectPicker, tagPicker, listSettingPicker, statPicker, preQueryPfadaufgaben, queryPfadaufgaben, infoOverlay,
  },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    pfadaufgaben() {
      return this.$store.state.training.currentTraining.pfadaufgabenIds;
    },
    preTags() {
      return this.$store.state.training.preTags;
    },
    settings() {
      return this.$store.state.training?.settings;
    },
    settingsSubjects() {
      return this.$store.state.training?.settings?.subjects?.length;
    },
    isFetching() {
      if (this.$store.state.training.isFetchingPrePfadaufgaben || this.$store.state.training.isFetchingPfadaufgaben) {
        return true;
      }
      return false;
    },
    wrongPlayed() {
      return this.$store.state.profile.me.userData.data.attributes.wrongPlayedExercise.ids;
    },
    recentPlayed() {
      return this.$store.state.profile.me.userData.data.attributes.recentPlayedExercise.ids;
    },
    stats() {
      return this.$store.state.training?.settings?.stats;
    },
    prePfadaufgaben() {
      return this.$store.state.training.prePfadaufgaben;
    },
    pfadaufgabenLimit() {
      return this.$store.state.training?.settings?.questions;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    currentSemester() {
      return this.$store.state.settings.currentSemester;
    },
    lernpfade() {
      const lernpfade = this.$store.getters.availableLernpfade(this.currentCourseId);
      const lernpfadeBySemester = [];
      lernpfade.forEach((lernpfad) => {
        if (lernpfad.Semester === this.currentSemester) {
          lernpfadeBySemester.push(lernpfad.Lernpfad.data.id);
        }
      });
      return lernpfadeBySemester;
    },
  },

  watch: {
    prePfadaufgaben() {
      this.pickRandomPfadaufgabe();
    },
    pfadaufgabenLimit() {
      this.pickRandomPfadaufgabe();
    },
    showOverlay() {
      this.editFilter();
    },
    settingsSubjects() {
      this.editFilter();
    },
  },

  methods: {

    editFilter() {
      if (this.settingsSubjects === 0 && this.lernpfade && this.showOverlay === 'pfadaufgabenTrainer') {
        this.$store.commit('manipulatePfadaufgabenFilter', this.lernpfade);
      }
    },

    pickRandomPfadaufgabe() {
      if (this.prePfadaufgaben) {
        const array = [...this.prePfadaufgaben];
        const randomArray = [];
        for (let i = 0; i < this.pfadaufgabenLimit && i < this.prePfadaufgaben.length; i += 1) {
          const max = array.length;
          const index = Math.floor(Math.random() * max);
          randomArray.push(array[index]);
          array.splice(index, 1);
        }
        this.$store.commit('setPreLimitedPfadaufgaben', randomArray);
      }
    },

    closeTrainer() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },

    openPicker(value) {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', value);
    },

    formatText(array) {
      return array.join(', ');
    },

    startTraining() {
      if (!this.isFetching) {
        if (this.prePfadaufgaben.length <= 1) {
          this.iosVibrate(1);
          this.$store.commit('toggleInfoOverlay', { value: true, index: 31, level: 2 });
        } else {
          this.iosVibrate(1);
          this.$store.commit('addToggle', {
            storeID: 'review', storeContainer: 'training', storeWrapper: 'settings', value: false,
          });
          this.$store.commit('startTraining', { type: 'pfadaufgabe' });
          this.$router.push({ name: 'exercises' });
        }
      }
    },
  },
};
</script>
