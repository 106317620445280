<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'acceptBattleMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const acceptBattleMutation = gql`
    mutation($battleId: ID!) {
      acceptBattle(battleId: $battleId) {
        data {
          id
        }
      }
    }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: acceptBattle } = useMutation(acceptBattleMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'acceptBattle', result: acceptBattle });
  },
};
</script>
