<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryPublicBattles',
  watch: {
    publicBattles() {
      this.$store.commit('setStrapiResult', { vuexModule: 'training', state: 'publicBattles', data: this.publicBattles?.publicBattles?.data });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'network-only',
    });

    const store = useStore();

    const queryPublicBattles = gql`
      query($courseId: ID!) {
        publicBattles(filters: {
          course: { id: {eq: $courseId }}
          public: { eq: true}
        }) {
          data {
            id
            attributes {
              settings
              creator {
                data {
                  id
                  attributes {
                    nickname
                    avatar {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      courseId: computed(() => store.state.settings.currentCourseID),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: publicBattles } = useQuery({
      query: queryPublicBattles,
      variables,
      context,
    });
    return { publicBattles };
  },
};
</script>
