<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'examPicker'" class="fixed md:absolute top-0 left-0 w-full h-screen md:h-full z-50 flex flex-row justify-center items-center pointer-events-none text-new-black font-visby">
      <div class="w-full h-full absolute bottom-0 pointer-events-auto">

        <!-- Content -->
        <div class="w-full h-1/6" @click="hidePicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">
          <!-- Fixed -->
          <div class="h-auto">
            <div class="text-xl" @click="hidePicker">schließen</div>
            <div class="mt-2 font-visby font-bold text-3xl">Filter nach Prüfungen</div>
          </div>
          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">
          <div v-if="exams" class="w-full md:auto bg-white rounded-xl shadow-new mt-5 border border-new-yellow2 overflow-hidden">
            <div v-for="(exam, index) in exams" :key="exam">
              <selector
                :label=exam?.attributes?.Index
                storeContainer='training'
                storeWrapper='settings'
                storeID='exams'
                storeID2='examsID'
                :value2=exam?.id
                :border="index < exams.length -1 ? true : false" />
            </div>
          </div>
          </div>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import selector from '../../../symbols/selector.vue';

export default {
  name: 'examPicker',
  mixins: [slideInOutBottom, iosVibrations],
  components: { selector },

  computed: {
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    me() {
      return this.$store.state.profile.me;
    },
    currentCourseIndex() {
      return this.$store.state.settings.currentCourseIndex;
    },
    exams() {
      return this.$store.state.training.mcExams;
    },
  },

  methods: {
    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', null);
    },
  },
};
</script>
