<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryTags',
  watch: {
    tags() {
      this.$store.commit('setStrapiResult', { vuexModule: 'training', state: 'tags', data: this.tags?.tags?.data });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const queryTags = gql`
      query($id: [ID] ) {
        tags(filters: {id: {in: $id}}) {
          data {
            id
            attributes {
              Bezeichnung
            }
          }
        }
      }
    `;

    const variables = reactive({
      id: computed(() => store.state.training.preTags),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: tags } = useQuery({
      query: queryTags,
      variables,
      context,
    });
    return { tags };
  },
};
</script>
