<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'preQueryPfadaufgaben',
  watch: {
    prePfadaufgaben() {
      this.$store.commit('setPrePfadaufgaben', this.prePfadaufgaben);
    },
    isFetching() {
      this.$store.commit('setFetchingIndicator', { key: 'PrePfadaufgaben', value: this.isFetching });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const preQueryPfadaufgaben = gql`
    query($filter: PfadaufgabenFiltersInput ) {
        pfadaufgabens(
          filters: $filter
          pagination: {limit: 100}
        ) {
          data {
            id
            attributes {
              Aufgabentyp {
                __typename
              }
              tags {
                data {
                  id
                }
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      filter: computed(() => store.state.training.settings.filter),
      limitFilter: computed(() => store.state.training.settings.limitFilter),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: prePfadaufgaben, isFetching } = useQuery({
      query: preQueryPfadaufgaben,
      variables,
      context,
    });
    return { prePfadaufgaben, isFetching };
  },
};
</script>
