<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryPfadaufgaben',
  watch: {
    pfadaufgaben() {
      this.$store.commit('setPfadaufgaben', this.pfadaufgaben);
    },
    isFetching() {
      this.$store.commit('setFetchingIndicator', { key: 'Pfadaufgaben', value: this.isFetching });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const queryPfadaufgaben = gql`
    query($id: [ID] ) {
        pfadaufgabens(
          filters: {id: {in: $id }}
          pagination: {limit: 100}
        ) {
          data {
            id
            attributes {
              Aufgabenbeschreibung
              index
              Aufgabentyp {
                __typename
                ... on ComponentAufgabenBegriffspaare {
                  Paar {
                    begriff1
                    begriff2
                  }
                  dummies {
                    answer
                  }
                }
                ... on ComponentAufgabenVokabel {
                  content
                  answer
                }
                ... on ComponentAufgabenMcFrage {
                  mc_frage {
                    data {
                      id
                    }
                  }
                }
                ... on ComponentAufgabenLueckentext {
                  content
                  dummies {
                    answer
                  }
                }
                ... on ComponentAufgabenListenzuordnung {
                  Liste {
                    Listenname
                    Answers
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      id: computed(() => store.state.training.preLimitedPfadaufgaben),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: pfadaufgaben, isFetching } = useQuery({
      query: queryPfadaufgaben,
      variables,
      context,
      fetchOnMount: false,
    });
    return { pfadaufgaben, isFetching };
  },
};
</script>
