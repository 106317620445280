<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'querySubjects',
  watch: {
    mcSubjects() {
      this.$store.commit('setMcSubjects', this.mcSubjects?.lernpfades?.data);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const querySubjects = gql`
    query($courseId: ID!) {
      lernpfades(
        pagination: {limit: 250}
        sort: "Bezeichnung:asc"
        filters: { kurse: { id: { eq: $courseId }} }
        ) {
        data {
          id
          attributes {
            Bezeichnung
          }
        }
      }
    }
    `;

    const variables = reactive({
      courseId: computed(() => store.state.settings.currentCourseID),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: mcSubjects } = useQuery({
      query: querySubjects,
      variables,
      context,
    });
    return { mcSubjects };
  },
};
</script>
