<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updatePublicBattleMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updatePublicBattleMutation = gql`
    mutation($id: ID!, $rounds: [ComponentBattleBattleRoundInput], $opponentId: ID!, $creatorProgress: JSON!, $opponentProgress: JSON!  ) {
      updatePublicBattle(id: $id, data: {
        creatorProgress: $creatorProgress
        opponentProgress: $opponentProgress
        battleRound: $rounds
        public: false
        started: true
        opponent: $opponentId
      }) {
        data {
          id
        }
      }
    }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updatePublicBattle } = useMutation(updatePublicBattleMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updatePublicBattle', result: updatePublicBattle });
  },
};
</script>
