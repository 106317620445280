<script>
export default {
  name: 'battleFunctions',

  data() {
    return {
      playerTwoReady: false,
    };
  },

  computed: {
    creatorOrOpponent() {
      if (this?.currentBattle?.creator?.data?.id === this.$store.state.profile.me?.userData?.data?.id) {
        return 'creator';
      }
      return 'opponent';
    },
    playerTwo() {
      if (this.creatorOrOpponent === 'creator') {
        return 'opponent';
      }
      return 'creator';
    },
    currentTraining() {
      return this.$store.state.training?.currentTraining;
    },
    currentBattle() {
      return this.$store.state.training?.currentBattle;
    },
    updateBattleRound() {
      return this.$store.state.updateBattleRound;
    },
    updatePublicBattle() {
      return this.$store.state.updatePublicBattle;
    },
    acceptBattle() {
      return this.$store.state.acceptBattle;
    },
    userDataId() {
      return this.$store.state.profile.me?.userData?.data?.id;
    },
    nextRoundNotification() {
      return this.$store.state.nextRoundNotification;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    queryCurrentBattleEx() {
      return this.$store.state.queryCurrentBattleEx;
    },
  },

  methods: {

    initBattle(battleId) {
      if (!this.isFetching) {
        const rounds = [];
        const playerProgres = {
          rounds: [],
        };
        const roundsCount = this.currentTraining.questionIds.length / 4;
        for (let i = 0; i < roundsCount; i += 1) {
          rounds.push({
            round: {
              roundNumber: i + 1,
              totalRounds: roundsCount,
              finished: false,
              progress: [],
              questionData: this.currentTraining.questionData.slice(i * 4, i * 4 + 4),
              questionIds: this.currentTraining.questionIds.slice(i * 4, i * 4 + 4),
              totalCorrect: 0,
              type: 'battle',
              battleId,
            },
          });
          playerProgres.rounds.push({
            roundNumber: i + 1,
            finished: false,
            points: 0,
          });
        }
        // Battle Runden erstellen
        this.updatePublicBattle({
          id: battleId, rounds, opponentId: this.userDataId, creatorProgress: playerProgres, opponentProgress: playerProgres,
        }).then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            console.log(result);
            // set currentTraining = round 1
            this.$store.commit('loadRound', rounds[0].round);
            this.$store.commit('startTraining', { type: 'battle' });
            // Push Benachrichtigung zum Creator
            this.acceptBattle({ battleId, courseId: this.currentCourseId }).then((result2) => {
              if (result2.error) {
                console.log(result2.error.message);
              } else {
                console.log(result2);
                this.$router.push({ name: 'questions' });
              }
            });
          }
        });
      }
    },

    endBattleRound() {
      this.$store.commit('showSecondOverlay', 'battleRoundOutro');
      // save progress to battle
      let key = null;
      let antiKey = null;
      if (this.creatorOrOpponent === 'creator') {
        key = 'creatorProgress';
        antiKey = 'opponentProgress';
      } else {
        key = 'opponentProgress';
        antiKey = 'creatorProgress';
      }
      // find current Round
      const roundIndex = this.currentBattle[key].rounds.findIndex((round) => round.roundNumber === this.currentTraining.roundNumber);
      // change finished & points
      if (roundIndex !== -1) {
        const { rounds } = this.currentBattle[key];
        rounds[roundIndex].finished = true;
        rounds[roundIndex].points = this.currentTraining.totalCorrect;
        // save round
        this.updateBattleRound({ id: this.currentTraining.battleId, [key]: this.currentBattle[key] })
          .then((result) => {
            if (result.error) {
              console.log(result.error.message);
            } else {
              this.queryCurrentBattleEx();
              console.log(result);
              // check if player two is ready
              const playerTwo = result.data.updatePublicBattle.data.attributes[antiKey];
              const playerTwoRoundIndex = playerTwo.rounds.findIndex((round) => round.roundNumber === this.currentTraining.roundNumber);
              if (playerTwo.rounds[playerTwoRoundIndex].finished) {
                // load next round, show Outro with Button
                this.playerTwoReady = true;
                // this.$store.commit('loadRound', rounds[0].round);
              } else {
                // show Outro with wait for player two
              }
            }
          });
      }
    },

    loadNextRound() {
      console.log('loading next round');
      this.queryCurrentBattleEx().then((result) => {
        console.log(result);
        const rounds = this.currentBattle.battleRound;
        console.log(this.currentBattle);
        let creatorRounds = 0;
        let opponentRounds = 0;
        this.currentBattle.creatorProgress.rounds.forEach((round) => {
          if (round.finished) creatorRounds += 1;
        });
        this.currentBattle.opponentProgress.rounds.forEach((round) => {
          if (round.finished) opponentRounds += 1;
        });
        const roundIndex = Math.min(creatorRounds, opponentRounds);
        console.log(roundIndex);
        this.notifyPlayerForNextRound();
        this.$store.commit('loadRound', rounds[roundIndex].round);
        this.$store.commit('startTraining', { type: 'battle' });
        this.$store.commit('showSecondOverlay', null);
        this.playerTwoReady = false;
      });
    },

    notifyPlayerForNextRound() {
      this.nextRoundNotification({ playerTwo: this?.currentBattle[this.playerTwo]?.data?.id, courseId: this.currentCourseId })
        .then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            console.log(result);
          }
        });
    },

    openBattle(battle) {
      // load current Round
      const rounds = battle.attributes.battleRound;
      let creatorRounds = 0;
      let opponentRounds = 0;
      battle.attributes.creatorProgress.rounds.forEach((round) => {
        if (round.finished) creatorRounds += 1;
      });
      battle.attributes.opponentProgress.rounds.forEach((round) => {
        if (round.finished) opponentRounds += 1;
      });
      const roundIndex = Math.min(creatorRounds, opponentRounds);
      this.$store.commit('loadRound', rounds[roundIndex].round);
      this.$store.commit('startTraining', { type: 'battle' });
      this.$router.push({ name: 'questions' });
    },

    openBattleResults(battle) {
      const rounds = battle.attributes.battleRound;
      const roundsCount = rounds.length - 1;
      this.$store.commit('loadRound', rounds[roundsCount].round);
      this.$store.commit('startTraining', { type: 'battle', finished: true });
      this.$router.push({ name: 'questions' });
    },
  },
};
</script>
